import { initializeApp } from 'firebase/app'
import { signOut, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth'
import UserData from './UserData'

const firebaseConfig = {
	apiKey: 'AIzaSyAHBbrEbyfNGkrtyZgn3-MmfXVIctWT_OY',
	authDomain: 'kimbapp-ed234.firebaseapp.com',
	projectId: 'kimbapp-ed234',
	storageBucket: 'kimbapp-ed234.appspot.com',
	messagingSenderId: '100198790396',
	appId: '1:100198790396:web:431bd9f6ab5beea7395379',
	measurementId: 'G-PKGZY6FH0G'
}

class Firebase {
	constructor (props) {
		this.app = initializeApp(firebaseConfig)
		this.auth = getAuth()
		onAuthStateChanged(this.auth, this.onChange)
	}

	createUserWithEmailAndPassword = (email, password) => {
		return createUserWithEmailAndPassword(this.auth, email, password)
			.then((userCredential) => {
				const user = userCredential.user
				console.log('sign-up', user)
			})
			.catch((error) => {
				const errorCode = error.code
				const errorMessage = error.message
				console.log(errorCode, errorMessage)
			})
	}

	signInWithEmailAndPassword = (email, password) => {
		return signInWithEmailAndPassword(this.auth, email, password)
			.then((userCredential) => {
			// Signed in
				const user = userCredential.user
				console.log('sign-in', user.uid, user)
				// this.firestore.collection('usersCollection')
				// 	.add({
				// 		uid: registeredUser.user.uid,
				// 		field: 'Info you want to get here',
				// 		anotherField: 'Another Info...'
				// 	})
			})
			.catch((error) => {
				const errorCode = error.code
				const errorMessage = error.message
				console.log(errorCode, errorMessage)
			})
	}

	signInWithGoogle () {
		const provider = new GoogleAuthProvider()
		return 	signInWithPopup(this.auth, provider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				// const credential = GoogleAuthProvider.credentialFromResult(result)
				// const token = credential.accessToken
				// The signed-in user info.
				const user = result.user
				console.log('sign-in', user.uid, user)
				return true
			}).catch((error) => {
			// Handle Errors here.
				const errorCode = error.code
				const errorMessage = error.message
				// The email of the user's account used.
				const email = error.email
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error)
				console.log(errorCode, errorMessage, email, credential)
				return false
			})
	}

	signOut () {
		return signOut(this.auth)
	}

	onChange = (user) => {
		if (user) {
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User
			const uid = user.uid
			console.log('sign-in', uid, user)
			UserData.isLogin.value = true
		} else {
			console.log('sign out')
			UserData.isLogin.value = false
		}
	}
}

export default new Firebase()
